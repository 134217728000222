// module imports
import React from 'react'
import { useTranslation } from 'react-i18next'
// local imports
import ClassCard from './ClassCard'

// style component imports
import {
  LearnWithoutLimitsContainer,
  LearnWithoutLimitsContents,
  ClassCardsContainer,
} from '../../styleComponents/LearnWithoutLimitsStyles'

// image imports
import GeneralEnglishCardImage from 'images/png/generalEnglishCard.png'
import BusinessEnglishCardImage from 'images/png/businessEnglishCard.png'
import AcademicEnglishCardImage from 'images/png/academicEnglishCard.png'
import IndividualEnglishCardImage from 'images/png/individualEnglishCard.png'

const Index = () => {
  const { t } = useTranslation('learnWithoutLimits')
  //these are the video URLs for the teacher/course section
  const generalEnglishVideo =
    'https://www.youtube.com/embed/sViNjvFcIxk?autoplay=1'
  const academicEnglishVideo =
    'https://www.youtube.com/embed/jPIrH29xjFI?autoplay=1'
  const businessEnglishVideo =
    'https://www.youtube.com/embed/z3oWQI_saGE?autoplay=1'
  const individualEnglishVideo =
    'https://www.youtube.com/embed/P1jaXmXoseI?autoplay=1'

  return (
    <LearnWithoutLimitsContainer>
      <LearnWithoutLimitsContents>
        <h1>{t('learnWithoutLimitsTitle')}</h1>
        <h3>{t('learnWithoutLimitsSubtitle')}</h3>
        <ClassCardsContainer>
          <ClassCard
            classType={t('learnWithoutLimitsGeneralHeader')}
            img={GeneralEnglishCardImage}
            videoURL={generalEnglishVideo}
            classDescription={t('learnWithoutLimitsGeneralDescription')}
          />
          <ClassCard
            classType={t('learnWithoutLimitsBusinessHeader')}
            img={BusinessEnglishCardImage}
            videoURL={businessEnglishVideo}
            classDescription={t('learnWithoutLimitsBusinessDescription')}
          />
          <ClassCard
            classType={t('learnWithoutLimitsAcademicHeader')}
            img={AcademicEnglishCardImage}
            videoURL={academicEnglishVideo}
            classDescription={t('learnWithoutLimitsAcademicDescription')}
          />
          <ClassCard
            classType={t('learnWithoutLimitsIndividualHeader')}
            img={IndividualEnglishCardImage}
            videoURL={individualEnglishVideo}
            classDescription={t('learnWithoutLimitsIndividualDescription')}
          />
        </ClassCardsContainer>
      </LearnWithoutLimitsContents>
    </LearnWithoutLimitsContainer>
  )
}

export default Index
