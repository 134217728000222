// Module Imports
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

// Local imports
import PlayButton from 'components/General/Buttons/PlayButton'
import BuyNowButton from 'components/General/Buttons/BuyNowButton'

// style imports
import {
  ClassCardContainer,
  ClassCardContents,
  ClassCardImgContainer,
} from 'components/styleComponents/LearnWithoutLimitsStyles'

// asset imports

const ClassCard = ({ classType, img, videoURL, classDescription }) => {
  const { t } = useTranslation('indexPage')

  // state variables for the toggle of the english teacher videos.
  const [IframeToggle, setIframeToggle] = useState(false)

  return (
    <ClassCardContainer>
      <ClassCardContents>
        <h3>{classType}</h3>
        <ClassCardImgContainer>
          {/* toggle ternary for the iframe video or the teacher image.  */}
          {!IframeToggle ? (
            <img src={img} alt={`${classType} English Teacher`} />
          ) : videoURL === 'NA' ? (
            <div>{t('comingSoon')}</div>
          ) : (
            <iframe
              src={videoURL}
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              title={`${classType} English`}
              frameBorder="0"
              allowFullScreen
            ></iframe>
          )}
          {/* toggle ternary for the playButton or the closeVideo button.  */}
          {!IframeToggle ? (
            <PlayButton actionFunction={setIframeToggle} parameter={true} />
          ) : (
            <span
              className="closeVideo"
              onClick={() => setIframeToggle(false)}
              onKeyDown={() => setIframeToggle(false)}
              role="button"
              tabIndex={0}
            >
              {t('closeVideo')}
            </span>
          )}
        </ClassCardImgContainer>
        <p>{classDescription}</p>
        <BuyNowButton />
      </ClassCardContents>
    </ClassCardContainer>
  )
}

export default ClassCard
