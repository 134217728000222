import styled from '@emotion/styled'

export const LearnWithoutLimitsContainer = styled.div`
  position: relative;
  width: 100vw;
  display: flex;
  justify-content: center;
  text-align: center;
  margin-top: 50px;
`

export const LearnWithoutLimitsContents = styled.div`
  position: relative;
  width: 85%;

  h3 {
    font-weight: normal;
    margin-bottom: 30px;
  }
`
export const ClassCardsContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
`

export const ClassCardContainer = styled.div`
  width: 23%;
  background-color: #f57189;
  border-radius: 10px;
  color: #ffffff;
  overflow: hidden;
  padding: 0px 10px;

  @media (max-width: 1100px) {
    width: 45%;
    margin-top: 10px;
  }

  @media (max-width: 700px) {
    width: 80%;
  }
`
export const ClassCardContents = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  h3 {
    margin-bottom: auto;
  }
  p {
    margin-top: auto;
  }
`

export const ClassCardImgContainer = styled.div`
  position: relative;
  margin: 10px;
  img {
    max-height: 100%;
    max-width: 100%;
  }
  iframe {
    max-height: 100%;
    max-width: 100%;
    border: none;
    margin: 0;
    padding: 0;
  }
  .closeVideo {
    text-align: center;
    cursor: pointer;
  }
`
